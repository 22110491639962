// @flow
import enPlural from './en-plural.json';

export default {
  lang: {
    // en,
  },
  langPlural: {
    en: enPlural,
  },
};

// export const languages = {
//   en,
// };
