import React from 'react';

export const SearchIcon = ({ style, ...rest }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, color: 'var(--color-black)', ...style }}
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.5 5c3.5898509 0 6.5 2.91014913 6.5 6.5 0 1.4341566-.4644677 2.7598309-1.2511859 3.8348056l3.0900207 3.0898156c.1952621.1952621.1952621.5118446 0 .7071068l-.7071068.7071068c-.1952622.1952621-.5118447.1952621-.7071068 0l-3.0898156-3.0900207C14.2598309 17.5355323 12.9341566 18 11.5 18 7.91014913 18 5 15.0898509 5 11.5 5 7.91014913 7.91014913 5 11.5 5zm0 2C9.01471863 7 7 9.01471863 7 11.5c0 2.4852814 2.01471863 4.5 4.5 4.5 2.4852814 0 4.5-2.0147186 4.5-4.5C16 9.01471863 13.9852814 7 11.5 7z"
    />
  </svg>
);

export const SearchCloseIcon = ({ style, ...rest }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, color: 'var(--color-black)', ...style }}
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.9497475 8.46446609L13.4142136 12l3.5355339 3.5355339-1.4142136 1.4142136L12 13.4142136l-3.53553391 3.5355339-1.41421356-1.4142136L10.5857864 12 7.05025253 8.46446609l1.41421356-1.41421356L12 10.5857864l3.5355339-3.53553387z"
    />
  </svg>
);

export const DropdownArrow = ({ style, ...rest }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.2744042 9.8292523l-5.5218275 6.31066c-.3636821.4156367-.9954447.4577542-1.4110813.0940721a.99998747.99998747 0 01-.0940721-.0940721l-5.52182753-6.31066c-.18184103-.20781831-.16078228-.52369962.04703604-.70554065C5.86377756 9.04395912 5.98077254 9 6.10188411 9H17.8981159c.2761424 0 .5.22385763.5.5 0 .12111157-.0439591.23810656-.1237117.3292523z"
    />
  </svg>
);

export const CheckboxOffIcon = ({ style, ...rest }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, color: 'var(--color-black)', ...style }}
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.85551601 4.0015395L14.8722296 4c1.7830375 0 2.42961.18565122 3.0814618.53426541.6518519.34861418 1.163429.86019133 1.5120432 1.51204318C19.8143488 6.69816044 20 7.34473292 20 9.1277704v5.7444592c0 1.7830375-.1856512 2.42961-.5342654 3.0814618-.3486142.6518519-.8601913 1.163429-1.5120432 1.5120432C17.3018396 19.8143488 16.6552671 20 14.8722296 20H9.1277704c-1.78303748 0-2.42960996-.1856512-3.08146181-.5342654-.65185185-.3486142-1.163429-.8601913-1.51204318-1.5120432-.33026607-.6175439-.51427176-1.2303494-.53272591-2.8092074L4 9.1277704c0-1.78303748.18565122-2.42960996.53426541-3.08146181.34861418-.65185185.86019133-1.163429 1.51204318-1.51204318.61754386-.33026607 1.23034945-.51427176 2.80920742-.53272591zm.03462139 1.99938844l-.41597945.00770635c-.82154936.02555632-1.15034434.11046801-1.48465164.28925751-.3033149.16221459-.52939992.38829961-.69161451.69161451l-.07597805.15571862c-.15072113.3444045-.21287559.75995309-.22098581 1.74491247v6.2197252l.00770635.4159795c.02555632.8215493.11046801 1.1503443.28925751 1.4846516.16221459.3033149.38829961.5293999.69161451.6916145l.15571862.0759781c.3444045.1507211.75995309.2128755 1.74491247.2209858L14.8722296 18c1.3237038 0 1.726809-.0778432 2.1382641-.2978918.3033149-.1622146.5293999-.3882996.6916145-.6916145l.0759781-.1557186c.1507211-.3444045.2128755-.7599531.2209858-1.7449125L18 9.1277704c0-1.32370383-.0778432-1.72680895-.2978918-2.13826409-.1622146-.3033149-.3882996-.52939992-.6916145-.69161451l-.1557186-.07597805c-.3444045-.15072113-.7599531-.21287559-1.7449125-.22098581H8.8901374z"
    />
  </svg>
);

export const CheckboxOnIcon = ({ style, ...rest }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, color: 'var(--color-brand)', ...style }}
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.8722296 4c1.7830375 0 2.42961.18565122 3.0814618.53426541.6518519.34861418 1.163429.86019133 1.5120432 1.51204318C19.8143488 6.69816044 20 7.34473292 20 9.1277704v5.7444592c0 1.7830375-.1856512 2.42961-.5342654 3.0814618-.3486142.6518519-.8601913 1.163429-1.5120432 1.5120432C17.3018396 19.8143488 16.6552671 20 14.8722296 20H9.1277704c-1.78303748 0-2.42960996-.1856512-3.08146181-.5342654-.65185185-.3486142-1.163429-.8601913-1.51204318-1.5120432C4.18565122 17.3018396 4 16.6552671 4 14.8722296V9.1277704c0-1.78303748.18565122-2.42960996.53426541-3.08146181.34861418-.65185185.86019133-1.163429 1.51204318-1.51204318C6.69816044 4.18565122 7.34473292 4 9.1277704 4h5.7444592zm-.2319549 5.26712193l-3.8458839 3.84992367-1.76159507-1.7634454-.9825432.9835753 2.74413827 2.7470207 4.8284271-4.833499-.9825432-.98357527z"
    />
  </svg>
);

export const CloseIcon = ({ style, ...rest }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, color: 'var(--color-black)', ...style }}
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.9497475 8.46446609L13.4142136 12l3.5355339 3.5355339-1.4142136 1.4142136L12 13.4142136l-3.53553391 3.5355339-1.41421356-1.4142136L10.5857864 12 7.05025253 8.46446609l1.41421356-1.41421356L12 10.5857864l3.5355339-3.53553387z"
    />
  </svg>
);

export const LockIcon = ({ style, ...rest }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 16, height: 16, color: 'var(--color-black)', ...style }}
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 3c2.6887547 0 4.8818181 2.12230671 4.9953805 4.78311038L17 8v2c.5522847 0 1 .4477153 1 1v8c0 .5522847-.4477153 1-1 1H7c-.55228475 0-1-.4477153-1-1v-8c0-.5522847.44771525-1 1-1V8c0-2.76142375 2.23857625-5 5-5zm0 9c-.8284271 0-1.5.6715729-1.5 1.5 0 .4181704.1711162.7963744.4471471 1.0684108l.1083002.0969077L10.5 18h3l-.5544351-3.3355037C13.2838623 14.3894645 13.5 13.9699731 13.5 13.5c0-.8284271-.6715729-1.5-1.5-1.5zm0-7c-1.5976809 0-2.90366088 1.24891996-2.99490731 2.82372721L9 8v2h6V8c0-1.65685425-1.3431458-3-3-3z"
    />
  </svg>
);

export const CheckMarkIcon = ({ ...rest }) => (
  <svg viewBox="0 0 24 24" {...rest}>
    <path
      d="M5.464 12.05L9 15.586l9.536-9.536 1.414 1.414L9 18.414l-4.95-4.95z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export const DisqualifiedIcon = ({ style, ...rest }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...rest}
  >
    <path
      d="M12 4a8 8 0 110 16 8 8 0 010-16zm4.89 4.523l-8.367 8.368a6 6 0 008.367-8.367zM12 6a6 6 0 00-4.89 9.477l8.367-8.368A5.973 5.973 0 0012 6z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export const LogoIcon = ({ ...rest }) => (
  <svg viewBox="0 0 48 48" {...rest}>
    <g fillRule="nonzero">
      <path
        d="M47.74 27.501c-1.933 13.112-14.13 22.173-27.24 20.24C7.387 45.807-1.674 33.61.26 20.5 2.193 7.388 14.39-1.673 27.5.26 40.612 2.195 49.673 14.39 47.74 27.5"
        fill="#2935FF"
      />
      <path
        d="M34.763 9.603c-4.85-3.066-8.321 3.687-8.403 3.895-.866 1.687-1.576 4.27-2.018 6.199l-6.318-.937-.25 1.706 6.24.922c-.174.888-.26 1.464-.26 1.464l-.341 1.873h.018l-.022.112-6.177-.91-.23 1.716 6.117.892c-1.61 8.117-2.798 10.562-3.995 11.893-.245.286-1.962 1.438-2.828-.62-.602-1.435-1.747-1.238-1.747-1.238-.323-.03-1.828.94.528 2.43 4.831 3.07 8.32-3.687 8.403-3.891.866-1.687 2.018-6.247 2.46-8.176l6.359.94.249-1.702-6.262-.9c.17-.891.26-1.464.26-1.464l.308-1.88h-.018l.018-.112 6.192.914.234-1.705-6.117-.915c1.609-8.116 2.356-8.588 3.553-9.934.245-.286 1.962-1.434 2.828.621.602 1.435 1.761 1.238 1.761 1.238.324.033 1.825-.94-.531-2.431"
        fill="#FFF"
      />
    </g>
  </svg>
);

export const RadioCheckedIcon = ({ style, ...rest }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...rest}
  >
    <path
      d="M12 3a9 9 0 110 18 9 9 0 010-18zm0 2a7 7 0 100 14 7 7 0 000-14zm0 2a5 5 0 110 10 5 5 0 010-10z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

/**
 * @param {Object} params
 * @param {Object} [params.style]
 */
export const RadioIcon = ({ style, ...rest }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...rest}
  >
    <path
      d="M12 3a9 9 0 110 18 9 9 0 010-18zm0 2a7 7 0 100 14 7 7 0 000-14z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export const CopyIcon = ({ style, ...rest }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 16, height: 16, ...style }}
    {...rest}
  >
    <path
      d="M16 1H4C3 1 2 2 2 3v14h2V3h12V1zm3 4H8C7 5 6 6 6 7v14c0 1 1 2 2 2h11c1 0 2-1 2-2V7c0-1-1-2-2-2zm0 16H8V7h11v14z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export const ExternalLinkIcon = ({ style, ...rest }) => (
  <svg viewBox="0 0 9 9" style={{ width: 9, height: 9, ...style }} {...rest}>
    <path
      d="M9 0v7H7l-.001-3.586L1.464 8.95.05 7.536 5.585 2H2V0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export const PassIcon = ({ style, ...rest }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.5 20h2c.27614237 0 .5-.2238576.5-.5v-8.6c0-.2761424-.22385763-.5-.5-.5h-2c-.27614237 0-.5.2238576-.5.5v8.6c0 .2761424.22385763.5.5.5zM21 11.2c0-.88-.72-1.6-1.6-1.6h-4.448l.76-3.656.024-.256c0-.328-.136-.632-.352-.848l-.1266794-.12548429c-.3621883-.35877148-.9295444-.38381243-1.3203856-.07693179l-.1044158.09450273L9.47 9.272C9.174 9.56 9 9.96 9 10.4v8c0 .88.52 1.6 1.4 1.6H17c.664 0 1.23-.4 1.47-.976l2.42-5.64c.072-.184.11-.376.11-.584v-1.6z"
    />
  </svg>
);

export const GearIcon = ({ style, ...rest }) => (
  <svg
    viewBox="0 0 40 40"
    style={{ width: 40, height: 40, color: 'var(--color-black)', ...style }}
    {...rest}
  >
    <path
      fill="currentColor"
      d="M3.55333333 22.7166667c-.29895598-1.7987741-.29895598-3.6345593 0-5.4333334C5.39 17.3266667 7.04 16.4466667 7.68166667 14.8983333c.64166666-1.55.09666666-3.34-1.23333334-4.605 1.06068845-1.48451986 2.35927346-2.783668 3.84333337-3.84499997 1.2666666 1.33 3.0566666 1.875 4.6066666 1.23333334 1.55-.64166667 2.4283334-2.29333334 2.3833334-4.12833334 1.7998623-.29932543 3.6368043-.29932543 5.4366666 0-.045 1.83666667.835 3.48666667 2.3833334 4.12833334 1.55.64166666 3.34.09666666 4.605-1.23333334 1.4845199 1.06068845 2.783668 2.35927346 3.845 3.84333337-1.33 1.2666666-1.875 3.0566666-1.2333334 4.6066666.6416667 1.55 2.2933334 2.4283334 4.1283334 2.3833334.2993254 1.7998623.2993254 3.6368043 0 5.4366666-1.8366667-.045-3.4866667.835-4.1283334 2.3833334-.6416666 1.55-.0966666 3.34 1.2333334 4.605-1.0606885 1.4845199-2.3592735 2.783668-3.8433334 3.845-1.2666666-1.33-3.0566666-1.875-4.6066666-1.2333334-1.55.6416667-2.4283334 2.2933334-2.3833334 4.1283334-1.7998623.2993254-3.6368043.2993254-5.4366666 0 .045-1.8366667-.835-3.4866667-2.3833334-4.1283334-1.55-.6416666-3.34-.0966666-4.605 1.2333334-1.48451986-1.0606885-2.783668-2.3592735-3.84499997-3.8433334 1.33-1.2666666 1.875-3.0566666 1.23333334-4.6066666-.64166667-1.55-2.29333334-2.4283334-4.12833334-2.3833334v-.0016666zM20 25c2.7614237 0 5-2.2385763 5-5s-2.2385763-5-5-5-5 2.2385763-5 5 2.2385763 5 5 5z"
    />
  </svg>
);

export const FlagIcon = ({ style, ...rest }) => (
  <svg
    viewBox="0 0 40 40"
    style={{ width: 40, height: 40, color: 'var(--color-black)', ...style }}
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.33333333 4.99999882H35.23c.2972844-.00049775.5723213.15741179.7217726.41439927.1494512.25698748.1506811.57412973.0032274.83226858L30 16.6666667l5.955 10.42c.1474537.2581388.1462238.5752811-.0032274.8322685-.1494513.2569875-.4244882.4148971-.7217726.4143981H6.66666667v8.3333334H3.33333333V4.99999882z"
    />
  </svg>
);

export const SlideBoardIcon = ({ style, ...rest }) => (
  <svg
    viewBox="0 0 40 40"
    style={{ width: 40, height: 40, color: 'var(--color-black)', ...style }}
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M21.6666667 28.3333333v5H30v3.3333334H10v-3.3333334h8.3333333v-5H6.66666667C5.74619208 28.3333333 5 27.5871412 5 26.6666667V6.66666667H3.33333333V3.33333333H36.6666667v3.33333334H35V26.6666667c0 .9204745-.7461921 1.6666666-1.6666667 1.6666666H21.6666667zm-5-18.3333333v11.6666667L25 15.8333333 16.6666667 10z"
    />
  </svg>
);

export const ReportIcon = ({ style, ...rest }) => (
  <svg
    viewBox="0 0 40 40"
    style={{ width: 40, height: 40, color: 'var(--color-black)', ...style }}
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M20.69 8.33333333H35c.9204746 0 1.6666667.74619209 1.6666667 1.66666667v23.3333333C36.6666667 34.2538079 35.9204746 35 35 35H5c-.92047458 0-1.66666667-.7461921-1.66666667-1.6666667V6.66666667C3.33333333 5.74619208 4.07952542 5 5 5h12.3566667L20.69 8.33333333zM18.3333333 15v13.3333333h3.3333334V15h-3.3333334zM25 20v8.3333333h3.3333333V20H25zm-13.3333333 3.3333333v5H15v-5h-3.3333333z"
    />
  </svg>
);

export const AMAIcon = ({ style, ...rest }) => (
  <svg
    viewBox="0 0 40 40"
    style={{ width: 40, height: 40, color: 'var(--color-black)', ...style }}
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M12.1516667 34.7066667l-8.81835218 1.9600188 1.96001881-8.8183522C4.0025529 25.4339719 3.32921144 22.7377432 3.33331452 20 3.33331452 10.795 10.795 3.33333333 20 3.33333333 29.205 3.33333333 36.6666667 10.795 36.6666667 20 36.6666667 29.205 29.205 36.6666855 20 36.6666855c-2.7377432.0041031-5.4339719-.6692384-7.8483333-1.9600188zM11.6666667 20c0 4.6023729 3.7309604 8.3333333 8.3333333 8.3333333S28.3333333 24.6023729 28.3333333 20H25c0 2.7614237-2.2385763 5-5 5s-5-2.2385763-5-5h-3.3333333z"
    />
  </svg>
);

export const ClosingCeremonyIcon = ({ style, ...rest }) => (
  <svg
    viewBox="0 0 40 40"
    style={{ width: 40, height: 40, color: 'var(--color-black)', ...style }}
    {...rest}
  >
    <path
      fill="#2D2926"
      fillRule="nonzero"
      d="M6.66666667 3.33333333H33.3333333C34.2538079 3.33333333 35 4.07952542 35 5v32.1266667c0 .2825413-.1426075.5459839-.3795948.6998236-.2369873.1538398-.5357834.1771201-.7937385.061843L20 31.7166667l-13.82666667 6.17c-.25768519.1151564-.55614646.0920523-.7930393-.0613896C5.14340119 37.6718351 5 37.4089123 5 37.1266667V5c0-.92047458.74619208-1.66666667 1.66666667-1.66666667zM20 22.5l4.8983333 2.575-.935-5.4533333L27.925 15.7583333l-5.4766667-.7966666L20 10l-2.45 4.9616667-5.475.7966666 3.9616667 3.8633334L15.1033333 25.075 20 22.5z"
    />
  </svg>
);

export const PlayIcon = ({ style, ...rest }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.75193551 5.438629l9.76730069 5.6975921c.4770519.2782803.6381881.890598.3599078 1.3676499-.0869187.1490037-.2109042.2729891-.3599078.3599079l-9.76730069 5.69759205c-.23852595.13914014-.54468482.05857202-.68382496-.17995394C7.0235042 18.30494898 7 18.21800882 7 18.1294815V5.8705185c0-.2761424.22385763-.5.5-.5.08852732 0 .17546748.0235042.25193551.0681105z"
    />
  </svg>
);
