import React from 'react';
import { secondsToTime } from '../../utils/time';

function useInterval(callback, delay) {
  const savedCallback = React.useRef(callback);

  React.useEffect(() => {
    savedCallback.current = callback;
  });

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

/**
 * @param {number} dd – date difference
 * @returns {import('utils/time').TimeParts}
 */
function calcParts(dd) {
  if (dd > 0) {
    return secondsToTime(dd / 1000);
  }

  return secondsToTime(0);
}

/**
 * @param {string} time
 * @returns {number}
 */
function dateDiff(time) {
  return new Date(time).getTime() - new Date().getTime();
}

export function Timer({ time, endCallback }) {
  /**  @type {[import('utils/time').TimeParts, Function]} */
  const [parts, setParts] = React.useState(calcParts(dateDiff(time)));
  const [delay, setDelay] = React.useState(1000);

  useInterval(() => {
    const dd = dateDiff(time);
    if (dd >= 0) {
      const p = calcParts(dd);
      if (p.d === 0 && p.h === 0 && p.m === 0 && p.s === 0) {
        setDelay(null);
        endCallback(true);
      }
      setParts(p);
    } else {
      setDelay(null);
    }
  }, delay);

  const daysString = parts.d
    ? `${parts.d} ${parts.d === 1 ? 'day' : 'days'} `
    : '';
  return <>{`${daysString}${parts.h}h ${parts.m}m ${parts.s}s`}</>;
}
