import React from 'react';
import { NavLink, useRouteMatch, useLocation } from 'react-router-dom';
import outy from 'outy';
import cn from 'classnames';

import { GeneralContext } from '../../context/general';
import { I18nContext } from '../../context/i18n';
import { useMediaQuery } from '../../hooks/mediaQuery';
import { config } from '../../config';
import { useObserver } from '../../hooks/observer';
import { LogoIcon, ExternalLinkIcon } from '../Icons';
import { Timer } from '../Timer';

import logoSrc from './logo.gif';
import s from './s.module.css';

function RegistrationOpenText({ date }) {
  const { fetchStatus } = React.useContext(GeneralContext);
  const { dateTimeFormat } = React.useContext(I18nContext);
  const dateDiff = new Date(date * 1000).getTime() - new Date().getTime();
  const millisecondsInDay = 1000 * 60 * 60 * 24;
  const isLessThenADay = dateDiff < millisecondsInDay;

  const registrationEndHandler = React.useCallback(() => {
    // let times = 0;
    // const doFetch = () => {
    //   fetchStatus().then((st) => {
    //     if (!st.registration_cutoff && times < 5) {
    //       times += 1;
    //       setTimeout(doFetch, 3000);
    //     }
    //   });
    // };
    //
    // doFetch();
    setTimeout(() => {
      fetchStatus();
    }, 3000);
  }, []);

  return isLessThenADay ? (
    <>
      Ends in{' '}
      <Timer
        time={date >= 0 ? date * 1000 : null}
        endCallback={registrationEndHandler}
      />
    </>
  ) : (
    <>Late registration open until {dateTimeFormat(date * 1000)}</>
  );
}

export function Header() {
  const { status, competitionState } = React.useContext(GeneralContext);
  const outyRef = React.useRef(null);
  const navElementRef = React.useRef(null);
  const [navOpen, setNavOpen] = React.useState(false);
  const headerRef = React.useRef(null);
  const isHeaderVisible = useObserver(headerRef.current, {
    threshold: 0.5,
    rootMargin: '240px',
  });

  const { pathname } = useLocation();
  const showSpaceRaceTheme = config.pagesWithSpaceRaceTheme.includes(pathname);
  const isHomePage = pathname === '/';

  React.useEffect(() => {
    if (navOpen) {
      outyRef.current = outy(
        [navElementRef.current],
        ['click', 'touchstart'],
        () => setNavOpen(false)
      );
    }

    if (navOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      if (outyRef.current && outyRef.current.remove) {
        outyRef.current.remove();
      }
    };
  }, [navOpen]);

  const scrollTop = React.useCallback(
    () => window.scrollTo({ top: 0, behavior: 'smooth' }),
    []
  );

  return (
    <>
      {competitionState === 'start' ? (
        <div className={s.registerHintMobile}>
          <RegistrationOpenText date={status.data.registration_cutoff_at} />
        </div>
      ) : null}
      {competitionState === 'completion' || competitionState === 'end' ? (
        <div className={s.registerHintMobile}>Registration is now closed</div>
      ) : null}
      <header className={s.header} ref={headerRef}>
        <nav className={s.west}>
          <ul
            className={cn(s.navList, { [s.navListOpen]: navOpen })}
            ref={navElementRef}
          >
            <li className={s.navListItem}>
              <a
                href={config.links.spacerace}
                target="_blank"
                rel="noopener noreferrer"
                className={cn(s.navLink, { [s.spaceRace]: showSpaceRaceTheme })}
              >
                Space Race
                <ExternalLinkIcon className={s.navLinkExternalIcon} />
              </a>
            </li>
            <li className={s.navListItem}>
              <a
                href={config.links.about}
                target="_blank"
                rel="noopener noreferrer"
                className={cn(s.navLink, { [s.spaceRace]: showSpaceRaceTheme })}
              >
                About
                <ExternalLinkIcon className={s.navLinkExternalIcon} />
              </a>
            </li>
            <li className={s.navListItem}>
              <NavLink
                to="/events"
                className={cn(s.navLink, { [s.spaceRace]: showSpaceRaceTheme })}
                activeClassName={s.navLinkActive}
                onClick={() => setNavOpen(false)}
              >
                Events
              </NavLink>
            </li>
            <li className={s.navListItem}>
              <NavLink
                to="/check"
                className={cn(s.navLink, { [s.spaceRace]: showSpaceRaceTheme })}
                activeClassName={s.navLinkActive}
                onClick={() => setNavOpen(false)}
              >
                Verify connectivity
              </NavLink>
            </li>
          </ul>
          <button
            type="button"
            className={s.menuToggleButton}
            onClick={() => setNavOpen((isShown) => !isShown)}
          >
            <svg viewBox="0 0 16 8">
              <path
                d="M16 6v2H0V6h16zm0-3v2H0V3h16zm0-3v2H0V0h16z"
                fill="#2D2926"
                fillRule="evenodd"
              />
            </svg>
          </button>
        </nav>
        <NavLink
          to="/"
          className={cn(s.logo, {
            [s.sticky]: isHeaderVisible === false,
            [s.homePage]: isHomePage,
          })}
          onClick={scrollTop}
        >
          {isHomePage && (
            <img src={logoSrc} width={86} height={61} alt="Filecoin Logo" />
          )}
          <LogoIcon />
        </NavLink>
        <div className={s.east} />
      </header>
    </>
  );
}
