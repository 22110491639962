/**
 * @typedef {Object} TimeParts
 * @property {number} d - days
 * @property {number} h - hours
 * @property {number} m - minutes
 * @property {number} s - seconds
 */

/**
 * @param {number} seconds
 * @returns {TimeParts}
 */
export function secondsToTime(seconds) {
  let s = seconds;
  const d = Math.floor(s / 86400); // 3600 * 24
  s -= d * 86400;
  const h = Math.floor(s / 3600);
  s -= h * 3600;
  const m = Math.floor(s / 60);
  s = Math.floor(s - m * 60);

  return { d, h, m, s };
}
