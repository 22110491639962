import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import GA from 'react-ga';

import { useMediaQuery } from '../../hooks/mediaQuery';
import { config } from '../../config';

import { Header } from '../Header';
import { Footer } from '../Footer';

import s from './s.module.css';

const DashboardPage = React.lazy(() => import('../DashboardPage'));
const RegisterPage = React.lazy(() => import('../RegisterPage'));
const DealsPage = React.lazy(() => import('../DealsPage'));
const EventsPage = React.lazy(() => import('../EventsPage'));
const CheckPage = React.lazy(() => import('../CheckPage'));

const PageLoading = () => null;

export function App() {
  /** @type {React.MutableRefObject<HTMLDivElement|null>} */
  const cosmonautRef = React.useRef(null);
  /** @type {React.MutableRefObject<HTMLDivElement|null>} */
  const planetBigRef = React.useRef(null);
  /** @type {React.MutableRefObject<HTMLDivElement|null>} */
  const planetSmallRef = React.useRef(null);

  const { pathname } = useLocation();
  const showSpaceRaceTheme = config.pagesWithSpaceRaceTheme.includes(pathname);

  const device = useMediaQuery();

  React.useEffect(() => {
    const listener = () => {
      const { scrollY, innerWidth } = window;

      if (scrollY > 400 || scrollY < 0 || innerWidth < 1200) {
        return;
      }

      const offset1 = scrollY * -0.2;
      const offset2 = scrollY * -0.1;

      if (cosmonautRef.current) {
        cosmonautRef.current.style.transform = `translateY(${offset1}px)`;
      }
      if (planetBigRef.current) {
        planetBigRef.current.style.transform = `translateY(${offset2}px)`;
      }
      if (planetSmallRef.current) {
        planetSmallRef.current.style.transform = `translateY(${offset1}px)`;
      }
    };

    const listenerOptions = { capture: true, passive: true };

    if (device === 'desktop') {
      window.addEventListener('scroll', listener, listenerOptions);
    } else {
      window.removeEventListener('scroll', listener, listenerOptions);
    }

    return () =>
      window.removeEventListener('scroll', listener, listenerOptions);
  }, [device]);

  React.useEffect(() => {
    const isProduction = process.env.NODE_ENV === 'production';

    if (isProduction) {
      GA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
    }
    // // DEV ENV
    // else {
    //   GA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS, {
    //     debug: true,
    //     titleCase: false,
    //   });
    // }

    GA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      {showSpaceRaceTheme && device === 'desktop' ? (
        <div className={s.themeWrap}>
          <div className={s.spaceLeft} />
          <div className={s.cosmonaut} ref={cosmonautRef} />
          <div className={s.spaceRight} />
          <div className={s.planetBig} ref={planetBigRef} />
          <div className={s.planetSmall} ref={planetSmallRef} />
        </div>
      ) : null}

      <Header />
      <main>
        <React.Suspense fallback={<PageLoading />}>
          <Switch>
            <Route exact path="/">
              <DashboardPage />
            </Route>
            <Route path="/register">
              <RegisterPage />
            </Route>
            <Route path="/events">
              <EventsPage />
            </Route>
            <Route path="/check">
              <CheckPage />
            </Route>
            <Route path="/deals/:id">
              <DealsPage />
            </Route>
            <Redirect to="/" />
          </Switch>
        </React.Suspense>
      </main>
      <Footer />
    </>
  );
}
