import * as React from 'react';

/**
 *
 * @param {HTMLElement} node
 * @param {IntersectionObserverInit} options
 * @returns {boolean|undefined}
 */
export const useObserver = (node, options = {}) => {
  const [state, setState] = React.useState(null);
  const stateRef = React.useRef(state);
  stateRef.current = state;

  const observer = React.useMemo(
    () =>
      new IntersectionObserver(
        ([el]) => {
          setState(el.isIntersecting);
        },
        {
          root: document.querySelector('main'),
          rootMargin: '0px',
          threshold: 0.1,
          ...options,
        }
      ),
    []
  );

  React.useEffect(() => {
    if (node) {
      observer.unobserve(node);
      observer.observe(node);
    } else {
      setState(null);
    }

    return () => {
      if (node) {
        observer.unobserve(node);
      }
      observer.disconnect();
    };
  }, [node, observer]);

  return state;
};
