import React from 'react';

const mqlPhone = window.matchMedia('(max-width: 767px)');
const mqlTablet = window.matchMedia(
  '(min-width: 768px) and (max-width: 1023px)'
);
const mqlDesktop = window.matchMedia('(min-width: 1024px)');

/**
 * @typedef {'phone'|'tablet'|'desktop'} DeviceType
 */

/**
 * @returns {DeviceType}
 */
export const useMediaQuery = () => {
  /** @param {[DeviceType, React.Dispatch<React.SetStateAction<DeviceType>>]} args */
  const [device, setDevice] = React.useState();

  React.useEffect(() => {
    function matchPhone(e) {
      if (e.matches) {
        setDevice('phone');
      }
    }

    function matchTablet(e) {
      if (e.matches) {
        setDevice('tablet');
      }
    }

    function matchDesktop(e) {
      if (e.matches) {
        setDevice('desktop');
      }
    }

    mqlPhone.addListener(matchPhone);
    mqlTablet.addListener(matchTablet);
    mqlDesktop.addListener(matchDesktop);

    if (mqlPhone.matches) {
      setDevice('phone');
    } else if (mqlTablet.matches) {
      setDevice('tablet');
    } else {
      setDevice('desktop');
    }
  }, []);

  return device;
};
