import React from 'react';
import ReactDOM from 'react-dom';
import { nanoid } from 'nanoid';
import { Notification } from '../components/Notification';
import { api } from '../utils/api';
import { generateInitialState, reducer } from '../utils/reducer';
import { generateErrorMessages } from '../utils/error';
import { noop } from '../utils/noop';
import '../jsdoc/general-context';

const defaultStatus = {
  power_cutoff: false,
  power_cutoff_at: '0',
  registration_cutoff: false,
  registration_cutoff_at: '0',
};

/** @type {import('react').Context<TGeneralContext>} */
export const GeneralContext = React.createContext({
  /** @type {import('utils/reducer').TUtilsReducerState<TGeneralContext$status|null>} */
  status: generateInitialState(null),
  /** @type {TGeneralContext$fetchStatus} */
  fetchStatus: () => Promise.resolve(defaultStatus),
  /** @type {TGeneralContext$competitionState|null} */
  competitionState: null,
  regions: {},
  notificationActions: {
    /** @type {TGeneralContext$addNotification} */
    addNotification: noop,
    /** @type {TGeneralContext$removeNotification} */
    removeNotification: noop,
    /** @type {TGeneralContext$clearNotifications} */
    clearNotifications: noop,
  },
});

const notificationsNode = document.querySelector('#notifications-root');

export const GeneralProvider = ({ children }) => {
  /** @type {[TGeneralContext$notification[], React.Dispatch<React.SetStateAction<TGeneralContext$notification[]>>]} */
  const [notifications, setNotifications] = React.useState([]);

  /** @type {[import('utils/reducer').TUtilsReducerState<TGeneralContext$status>, import('react').Dispatch<import('utils/reducer').TUtilsReducerAction<TGeneralContext$status>>]} */
  // @ts-ignore
  const [status, dispatchStatus] = React.useReducer(
    reducer,
    generateInitialState(defaultStatus)
  );

  const [regions, setRegions] = React.useState({});

  /** @type {TGeneralContext$fetchStatus} */
  // @ts-ignore
  const fetchStatus = React.useCallback(() => {
    dispatchStatus({ type: 'start' });
    return api('/miner/competition-status')
      .then((/** @type {TGeneralContext$status} */ data) => {
        dispatchStatus({
          type: 'success',
          data,
        });
        return data;
      })
      .catch((err) =>
        dispatchStatus({ type: 'fail', errors: generateErrorMessages(err) })
      );
  }, []);

  React.useEffect(() => {
    fetchStatus();

    api('/location/regions').then((regionsResponse) =>
      setRegions(regionsResponse)
    );
  }, []);

  /** @type {TGeneralContext$competitionState|null} */
  const competitionState = React.useMemo(() => {
    if (status.loaded) {
      if (status.data.registration_cutoff && !status.data.power_cutoff) {
        return 'completion';
      }

      if (status.data.power_cutoff) {
        return 'end';
      }
      return 'start';
    }

    return null;
  }, [status]);

  /** @type {TGeneralContext$addNotification} */
  const addNotification = React.useCallback(
    (message, { level = 'error', timeout, override = false } = {}) => {
      if (message) {
        const nItem = { id: nanoid(), message, level, timeout };

        setNotifications((ns) => [...(override ? [] : ns), nItem]);
      }
    },
    [setNotifications]
  );

  /** @type {TGeneralContext$removeNotification} */
  const removeNotification = React.useCallback(
    (id) => {
      setNotifications((prevNotifications) =>
        prevNotifications.filter((n) => n.id !== id)
      );
    },
    [setNotifications]
  );

  /** @type {TGeneralContext$clearNotifications} */
  const clearNotifications = () => {
    setNotifications([]);
  };

  /**
   * @type {TGeneralContext}
   */
  const value = {
    status,
    competitionState,
    fetchStatus,
    regions,
    notificationActions: {
      addNotification,
      removeNotification,
      clearNotifications,
    },
  };

  return (
    <GeneralContext.Provider value={value}>
      {children}
      {notificationsNode &&
        ReactDOM.createPortal(
          <>
            {notifications.map((n) => (
              <Notification key={n.id} n={n} onClose={removeNotification} />
            ))}
          </>,
          notificationsNode
        )}
    </GeneralContext.Provider>
  );
};
