import React from 'react';
import { unitsMap } from '../i18n/unit';
import '../jsdoc/i18n-context';
import { noop } from '../utils/noop';
import i18n from '../i18n';

/**
 * @type {import('react').Context<TI18nContext>}
 */
export const I18nContext = React.createContext({
  /** @type {TI18nContext$pluralize} */
  pluralize: noop,
  /** @type {TI18nContext$numberFormat} */
  numberFormat: noop,
  /** @type {TI18nContext$dateTimeFormat} */
  dateTimeFormat: noop,
});

let unitSupport = false;
try {
  Intl.NumberFormat('en', { style: 'unit', unit: 'byte' });
  unitSupport = true;
} catch (e) {
  console.log(
    'No support for NumberFormat style: "unit"',
    'https://www.ecma-international.org/ecma-402/#sec-initializenumberformat'
  );
}

export const I18nProvider = ({ children }) => {
  const [lang /* , setLang */] = React.useState('en');
  const [translatePlural /* , setTranslatePlural */] = React.useState(i18n.langPlural.en);

  /** @type {TI18nContext$pluralize} */
  const pluralize = (n, fieldName) => {
    const pr = Intl.PluralRules ? new Intl.PluralRules(lang) : null;

    const hashMap = translatePlural[fieldName] || {};

    if (!pr) {
      // eslint-disable-next-line no-console
      console.error('Intl.PluralRules is not supported!');

      const keys = Object.keys(hashMap);

      if (keys.length) {
        return hashMap[keys[0]];
      }

      return '';
    }

    const rule = pr.select(n);
    return hashMap[rule];
  };

  /** @type {TI18nContext$numberFormat} */
  const numberFormat = React.useCallback(
    (value, options = {}, toParts) => {
      if ((options.unit || options.currency) && !unitSupport) {
        if (toParts) {
          if (options.unit) {
            return [
              { type: 'integer', value },
              { type: 'literal', value: ' ' },
              { type: 'unit', value: unitsMap[options.unit] ?? options.unit },
            ];
          }
          // TODO: add other "if" cases
        } else {
          const unit =
            options.currency || (unitsMap[options.unit] ?? options.unit);
          return unit ? `${value} ${unit}` : value;
        }
      }

      const nf = new Intl.NumberFormat(lang, options);

      return toParts ? nf.formatToParts(value) : nf.format(value);
    },
    [lang]
  );

  /** @type {TI18nContext$dateTimeFormat} */
  const dateTimeFormat = (
    value,
    options = { year: 'numeric', month: 'long', day: 'numeric' }
  ) => {
    const dtf = Intl.DateTimeFormat
      ? new Intl.DateTimeFormat(lang, options)
      : null;

    if (!dtf) {
      return value.toLocaleString();
    }

    return dtf.format(value);
  };

  return (
    <I18nContext.Provider
      value={{
        pluralize,
        numberFormat,
        dateTimeFormat,
      }}
    >
      {children}
    </I18nContext.Provider>
  );
};
